import { useDisableBodyScroll } from "@naf/teamscheme";
import type { TrackEventProps } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme";
import type React from "react";
import {
  useState,
  useRef,
  useEffect,
  type ImgHTMLAttributes,
  useCallback,
  type Dispatch,
  type SetStateAction,
  type HTMLAttributes,
  forwardRef,
} from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { useTracking } from "../../Tracking";

const TRANSITION_DURATION_MS = 150;

export interface ZoomableScrollImageProps
  extends Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "src" | "onClick" | "width"
  > {
  isZoomed: boolean;
  setIsZoomed: Dispatch<SetStateAction<boolean>>;
  alt: string;
  src: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onOpenEvent?: TrackEventProps;
  width?: number;
}

interface TransformAtributes {
  translateX: number;
  translateY: number;
  scaleX: number;
  scaleY: number;
}

export const defaultAspectRatio = 1654 / 2339;

function transformString({
  translateX,
  translateY,
  scaleX,
  scaleY,
}: TransformAtributes) {
  return `translateX(${translateX}px) translateY(${translateY}px) scaleX(${scaleX}) scaleY(${scaleY})`;
}

export default function ZoomableScrollImage({
  className,
  isZoomed,
  setIsZoomed,
  onClick,
  alt,
  src,
  onOpenEvent,
  width = 450,
  ...props
}: ZoomableScrollImageProps) {
  const [isMagnified, setIsMagnified] = useState<boolean>(false);
  const [savedTransform, saveTransform] = useState<TransformAtributes>({
    translateX: 0,
    translateY: 0,
    scaleX: 0,
    scaleY: 0,
  });
  const [scrollTop, setScrollTop] = useState(0);
  const imgEl = useRef<HTMLImageElement>(null);
  const magnifiedImgEl = useRef<HTMLImageElement>(null);
  const canvasEl = useRef<HTMLDivElement>(null);

  useDisableBodyScroll(isMagnified);

  const applyZoomAnimation = useCallback(() => {
    if (!imgEl.current) return;

    const PADDING = window.innerWidth <= 720 ? 4 : 12;

    const original = imgEl.current.getBoundingClientRect();

    const MAX_ZOOMED_WIDTH = 1200;

    const canvasHeight = window.innerHeight - PADDING * 2;
    const canvasWidth = document.body.clientWidth - PADDING * 2;

    const canvas = {
      left: PADDING,
      top: PADDING,
      width: canvasWidth,
      height: canvasHeight,
      right: PADDING + canvasWidth,
      bottom: PADDING + canvasHeight,
    };

    const targetWidth = Math.min(MAX_ZOOMED_WIDTH, canvasWidth);
    const targetHeight = targetWidth * (original.height / original.width);
    const canvasCenterX = canvas.left + canvasWidth / 2;
    const canvasCenterY = canvas.top + canvasHeight / 2;

    const targetTop = Math.max(canvas.top, canvasCenterY - targetHeight / 2);

    const target = {
      left: canvasCenterX - targetWidth / 2,
      top: targetTop,
      right: canvasCenterX + targetWidth / 2,
      bottom: targetTop + targetHeight,
    };

    const originalCenterX = original.left + original.width / 2;
    const originalCenterY = original.top + original.height / 2;
    const targetCenterX = target.left + targetWidth / 2;
    const targetCenterY = target.top + targetHeight / 2;

    const transformObject = {
      scaleX: original.width / targetWidth,
      scaleY: original.height / targetHeight,
      translateX: originalCenterX - targetCenterX,
      translateY: originalCenterY - targetCenterY + scrollTop,
    };

    magnifiedImgEl.current?.animate(
      [{ transform: transformString(transformObject) }, { transform: "none" }],
      {
        duration: TRANSITION_DURATION_MS,
        easing: "ease",
      },
    );

    saveTransform(transformObject);
    setIsMagnified(true);
  }, [scrollTop]);

  const tracking = useTracking();

  useEffect(() => {
    if (onOpenEvent && isMagnified) tracking.event(onOpenEvent);
  }, [tracking, onOpenEvent, isMagnified]);

  useEffect(() => {
    if (isZoomed) {
      applyZoomAnimation();
    }
  }, [isZoomed, applyZoomAnimation]);

  const removeZoomAnimation = useCallback(() => {
    const currentCanvas = canvasEl.current;
    if (!currentCanvas) return;
    if (isMagnified) {
      setTimeout(() => {
        setIsMagnified(false);
      }, TRANSITION_DURATION_MS);
    }
    magnifiedImgEl.current?.animate(
      [
        { transform: "none" },
        {
          transform: transformString({
            ...savedTransform,
            translateY:
              savedTransform.translateY - scrollTop + currentCanvas.scrollTop,
          }),
        },
      ],
      {
        duration: TRANSITION_DURATION_MS,
        easing: "ease",
      },
    );
    setScrollTop(currentCanvas.scrollTop);
  }, [isMagnified, savedTransform, scrollTop]);

  useEffect(() => {
    if (!isZoomed) {
      removeZoomAnimation();
    }
  }, [isZoomed, removeZoomAnimation]);

  useEffect(() => {
    if (isMagnified) {
      const escapeClose = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          setIsZoomed(false);
        }
      };

      window.addEventListener("keydown", escapeClose);
      return () => window.removeEventListener("keydown", escapeClose);
    }
  }, [isMagnified, setIsZoomed]);

  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return (
    <>
      <Image
        data-hj-suppress
        className={className}
        ref={imgEl}
        onClick={function (event) {
          if (onClick) {
            onClick(event);
          }
        }}
        alt={alt || ""}
        src={src}
        width={width}
        height={(1 / defaultAspectRatio) * width}
        {...props}
      />
      {mounted &&
        ReactDOM.createPortal(
          <ImageContainer
            isMagnified={isMagnified}
            onClick={removeZoomAnimation}
          >
            <ImageCanvas ref={canvasEl}>
              <ImageMagnified
                data-hj-suppress
                alt=""
                src={src}
                ref={magnifiedImgEl}
                style={{
                  transformOrigin: "center",
                }}
              />
            </ImageCanvas>
          </ImageContainer>,
          document.body,
        )}
    </>
  );
}

export const Image = styled.img`
  width: 100%;
  cursor: zoom-in;
  height: auto;
`;

const ImageContainer = styled(function ({
  isMagnified: _,
  ...props
}: HTMLAttributes<HTMLDivElement> & { isMagnified: boolean }) {
  return <div {...props} />;
})`
  cursor: zoom-out;
  display: none;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;

  ${({ isMagnified }) =>
    isMagnified
      ? css`
          display: flex;
        `
      : null}
`;

const ImageCanvas = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.space4};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${({ theme }) => onDesktop`
    padding: ${theme.spacing.space12};
  `}
`;

const ImageMagnified = styled.img`
  display: block;
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: auto;
`;
